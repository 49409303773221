import {Injectable, signal} from '@angular/core';
import {toObservable} from "@angular/core/rxjs-interop";
import {Company} from "../../../../graphql/generated";

@Injectable({
    providedIn: 'root'
})
export class CompanySearchService {

    query = signal<string>('');
    query$ = toObservable(this.query);

    constructor() {
    }

    filterCompanies(companies: Company[]): Company[] {
        return companies.filter(
            company => company.name.toLowerCase().includes(
                this.query().toLowerCase()
            )
        );
    }
}
